import React, { useState } from 'react'
import useMedia from 'use-media'

import Layout from '../../layout/live-coding-layout'
import { SEO, Image } from '../../components'
import { decodeQueryString, canUseWindow } from '../../utils'

import './live-coding.scss'
import OgImage from '../../assets/images/livechat-live-coding-3-og.png'

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 950 })

  const [overlay, setOverlay] = useState(initialState)

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="May 20th, 2021"
      headerCta={{
        label: 'Join Discord',
        url: 'https://discord.gg/gRzwSaCxg4',
      }}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Leverage your skills in the area of server-side app authorization and have fun fun!"
        image={OgImage}
      />
      <section
        className="u-text-center u-Px-xl live-coding-bg"
        style={{ width: '100vw', height: 'calc(100vh - 90px)' }}
      >
        <Image
          type="privateAppsOverview2"
          alt="live-coding"
          className="u-mb-lg u-mx-auto"
          wrapperStyles={{ width: isWide ? '30%' : '80%' }}
        />

        <div className="">
          <h2 className="u-text-p3 u-mt-0 u-mb-sm">Thank you</h2>
          <h3 className="u-text-p5">See you at the live coding!</h3>
        </div>

        <div className="u-Mt-xs u-flex u-items-center u-justify-center u-flex-col sm:u-flex-row">
          <a
            href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210520T150000Z%2F20210520T170000Z&details=Learn%20Server-side%20App%20Authorization%0AJoin%20us%20for%20another%20live%20coding%20session%20hosted%20by%20the%20LiveChat%20Developer%20Program.%20This%20time%2C%20we%27ll%20teach%20you%20LiveChat%20authorization%20for%20server-side%20applications.%20It%27ll%20open%20you%20the%20door%20to%20building%20powerful%20integrations%2C%20for%20example%2C%20the%20ones%20that%20make%20use%20of%20webhooks.%0A%0ABuild%20your%20coding%20skills%2C%20become%20part%20of%20the%20LiveChat%20developer%20community%2C%20and%20win%20prizes.%0AWhere%3A%20%3Ca%20href%3D%22https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dei8YG9mLdfI%22%20title%3D%22LiveChat%20Live%20coding%20Youtube%22%20%3EYouTube%3C%2Fa%3E%0AHost%3A%20%3Ca%20href%3D%22https%3A%2F%2Fwww.livechat.com%2Fteam%2F%23jakub-sikora%22%20title%3D%22Jakub%20Sikora%20Frontend%20Lead%22%20%3EJakub%20Sikora%2C%20Frontend%20Lead%3C%2Fa%3E%0ATo%20read%20more%20information%20about%20the%20session%2C%20visit%20the%20event%20website%3A%20%3Ca%20href%3D%22https%3A%2F%2Fdevelopers.livechat.com%2Flive-coding-server-side-app-authorization%2F%22%20title%3D%22LiveChat%20Developer%20Program%3C%22%20%3ELiveChat%20Developer%20Program%3C%2Fa%3E&text=Live%20coding%3A%20Authorizing%20server-side%20apps%20with%20LiveChat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Add to Calendar</button>
          </a>

          <span className="u-m-md">and</span>
          <a
            href={process.env.GATSBY_CONSOLE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Join Developer Console</button>
          </a>
        </div>

        <div className="u-Mt-xl u-flex u-flex-col u-items-center">
          <h3 className="u-text-p5">See our previous sessions:</h3>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Building LiveChat apps 101
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            href="https://youtu.be/FDmLqEiCH60"
            target="_blank"
            rel="noopener noreferrer"
          >
            Authorizing web apps
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default LiveCoding
